import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { loginTranslationConfig } from '../translation.config';
import { FeatureLoginPage } from '../../common/constants/translationConstants';
import { experimentLayer, urlConstants } from '../constants/loginConstants';
import useExperiments from '../../common/hooks/useExperiments';

export const ForgotCredentialLink = ({ translate }: WithTranslationsProps): JSX.Element => {
  const { forgotCredentialsUrl } = urlConstants;
  const usernameRecoveryDeprecated = useExperiments(experimentLayer)
    .usernameRecoveryDeprecated as boolean;

  const linkTextKey = usernameRecoveryDeprecated
    ? FeatureLoginPage.ActionNeedHelp
    : FeatureLoginPage.ActionForgotPasswordOrUsernameQuestionCapitalized;
  return (
    <div className='text-center forgot-credentials-link'>
      <a
        id='forgot-credentials-link'
        className='text-link'
        href={forgotCredentialsUrl}
        target='_self'>
        {translate(linkTextKey)}
      </a>
    </div>
  );
};

export default withTranslations(ForgotCredentialLink, loginTranslationConfig);
