import React from 'react';
import { render } from 'react-dom';
import { ready } from 'core-utilities';
import LoginBase from './containers/LoginBase';
import { reactLoginPageContainer } from '../common/constants/browserConstants';
import '../../../css/login/login.scss';

function renderApp() {
  const entryPoint = reactLoginPageContainer();
  if (entryPoint) {
    render(<LoginBase />, entryPoint);
  } else {
    // Recursively call renderApp if target div not found
    // Callback will be triggered before every repaint
    window.requestAnimationFrame(renderApp);
  }
}

ready(() => {
  renderApp();
});
