import React, { useEffect } from 'react';
import { WithTranslationsProps } from 'react-utilities';
import { AccountSwitcherService } from 'Roblox';
import { TLoggedInUsers } from '../../common/types/accountSwitcherTypes';

export type loginAccountSwitcherProps = {
  containerId: string;
  titleText: string;
  helpText: string;
  onAccountSwitched: () => void;
  handleAddAccount: () => void;
  removeInvalidActiveUser: boolean;
  translate: WithTranslationsProps['translate'];
  loggedInUsers?: TLoggedInUsers;
};

export const LoginAccountSwitcher = ({
  containerId,
  titleText,
  helpText,
  onAccountSwitched,
  handleAddAccount,
  removeInvalidActiveUser,
  translate,
  loggedInUsers
}: loginAccountSwitcherProps): JSX.Element => {
  const LoginAccountSwitcherParameters = {
    containerId,
    titleText,
    helpText,
    onAccountSwitched,
    handleAddAccount,
    removeInvalidActiveUser,
    translate,
    loggedInUsers
  };

  const [
    isAccountSwitchingEnabledForBrowser
  ] = AccountSwitcherService?.useIsAccountSwitcherAvailableForBrowser() ?? [false];

  useEffect(() => {
    if (isAccountSwitchingEnabledForBrowser) {
      // eslint-disable-next-line no-void
      void AccountSwitcherService?.renderAccountSwitcher(LoginAccountSwitcherParameters);
    }
  }, [LoginAccountSwitcherParameters, isAccountSwitchingEnabledForBrowser]);

  return <div id={containerId} />;
};

export default LoginAccountSwitcher;
